<template>
  <div class="content">
    <!-- <router-link to="{name:'/bigData'}"></router-link> -->
    <!-- <router-link :to="{name:'b'}"></router-link> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // chooseItem: '',
      // asideMenuList: [{}]
    }
  }
}
</script>
<style lang="less" scoped>
</style>
